exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-prismic-blog-post-uid-tsx": () => import("./../../../src/pages/blog/{prismicBlogPost.uid}.tsx" /* webpackChunkName: "component---src-pages-blog-prismic-blog-post-uid-tsx" */),
  "component---src-pages-disclaimer-tsx": () => import("./../../../src/pages/disclaimer.tsx" /* webpackChunkName: "component---src-pages-disclaimer-tsx" */),
  "component---src-pages-forklifts-tsx": () => import("./../../../src/pages/forklifts.tsx" /* webpackChunkName: "component---src-pages-forklifts-tsx" */),
  "component---src-pages-hire-tsx": () => import("./../../../src/pages/hire.tsx" /* webpackChunkName: "component---src-pages-hire-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-used-forklifts-tsx": () => import("./../../../src/pages/used-forklifts.tsx" /* webpackChunkName: "component---src-pages-used-forklifts-tsx" */)
}

